import { Card, Tag } from 'antd';
import {
	UserOutlined,
	CalendarOutlined,
	TagsOutlined,
} from '@ant-design/icons';
export interface BlogPostProps {
	title: string;
	author: string;
	date: string;
	tags: string[];
	body: string;
}

function BlogPost({
	title,
	author,
	date,
	tags, 
	body, 
}: BlogPostProps) {
	return (
		<Card
			title={title}
			bordered={false}
			actions={[
				<Tag icon={<UserOutlined />}>
					{author}
				</Tag>,
				<Tag icon={<CalendarOutlined />}>
					{date}
				</Tag>,
				<Tag icon={<TagsOutlined />}>
					{tags}
				</Tag>
			]}
		>
			{body}
		</Card>
	);
}

export default BlogPost;
