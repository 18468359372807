import { Typography } from 'antd';
import styled from 'styled-components';

import ColorSection from 'Components/PageSections/ColorSection';
import SectionContent from 'Components/PageSections/SectionContent';

import rtk from 'Assets/Images/rtk.jpg';
import rim from 'Assets/Images/oral-cancer-imaging.png';

const { Title, Paragraph } = Typography;

const BodyText = styled(Paragraph)`
	font-size: 22px;
`;

const RTKImage = styled.div`
	min-width: 600px;
	height: 475px;
	overflow: hidden;
	background:
		url(${rtk})
		60px 100% / cover
		no-repeat;
`;

const RIMImage = styled.div`
	min-width: 600px;
	height: 475px;
	overflow: hidden;
	background:
		url(${rim})
		center / contain
		no-repeat;
`;

function Products() {
	return (
		<>
			<ColorSection>
				<SectionContent paddingTop={100}>
					<div>
						<Title>RTK-19</Title>
						<BodyText>
							This device’s first specification is to diagnose various bacterial, viral, and chronic diseases—such as cancer and COVID-19—within 5 minutes.
							The device scans a body fluid sample to identify biomarkers associated with these diseases.
						</BodyText>
					</div>
					<RTKImage />
				</SectionContent>
			</ColorSection>

			<section>
				<SectionContent>
					<RIMImage />
					<div>
						<Title>RIM-25</Title>
						<BodyText>
							This device’s first specification is to scan the human body for oral cancer by imaging body tissues.
						</BodyText>
					</div>
				</SectionContent>
			</section>
		</>
	);
}

export default Products;
