import SectionContent from 'Components/PageSections/SectionContent';
import BlogPost, { BlogPostProps } from './BlogPost';

const posts: BlogPostProps[] = [
	{
		title: "XPrize Semi-Finalists",
		author: "Peter Chadri",
		date: "September 25, 2020",
		tags: ["Covid-19", "X-Prize"],
		body: `Dawatek, a medical device startup founded by four young,
		 minority Chicagoans, was selected to compete in the prestigious
		 world-wide X-Prize Rapid Covid Testing competition to develop easy-to-use,
		 reliable, and rapid Covid-19 tests.`,
	}
];

function Updates() {
	return (
		<SectionContent
			vertical
			paddingLeft={300}
			paddingRight={300}
		>
			{posts.map((post) => (
				<BlogPost {...post} />
			))}
		</SectionContent>
	);
}

export default Updates;
