import { ReactNode } from 'react';
import styled from 'styled-components';
import { MAX_CONTENT_WIDTH } from 'Constants/MaxContentWidth';

const Container = styled.div<{
	vertical?: boolean;
	centered?: boolean;
	paddingTop?: number;
	paddingRight?: number;
	paddingBottom?: number;
	paddingLeft?: number;
}>`
	max-width: ${MAX_CONTENT_WIDTH}px;
	margin: auto;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: ${p => p.paddingTop === 0 ? 0 : p.paddingTop || 150}px;
	padding-right: ${p => p.paddingRight === 0 ? 0 : p.paddingRight || 200}px;
	padding-bottom: ${p => p.paddingBottom === 0 ? 0 : p.paddingBottom || 150}px;
	padding-left: ${p => p.paddingLeft === 0 ? 0 : p.paddingLeft || 200}px;

	${p => p.vertical && `
		flex-direction: column;
	`}

	${p => p.centered && `
		text-align: center;
	`}

	${p => !p.vertical && `
		> :last-child {
			margin: 0;
		}
		
		> * {
			margin-right: 100px;
		}
	`}
`;

interface SectionContentProps {
	children?: ReactNode;
	vertical?: boolean;
	centered?: boolean;
	paddingTop?: number;
	paddingRight?: number;
	paddingBottom?: number;
	paddingLeft?: number;
}

function SectionContent({
	children,
	vertical,
	centered,
	paddingTop,
	paddingRight,
	paddingBottom,
	paddingLeft,
}: SectionContentProps) {
	return (
		<Container
			vertical={vertical}
			centered={centered}
			paddingTop={paddingTop}
			paddingRight={paddingRight}
			paddingBottom={paddingBottom}
			paddingLeft={paddingLeft}
		>
			{children}
		</Container>
	);
}

export default SectionContent;
