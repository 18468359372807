import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import {
  Layout,
  Typography,
} from 'antd';
import styled from 'styled-components';

import './App.less';
import NavBar from 'Components/NavBar';
import Home from 'Pages/Home';
import About from 'Pages/About';
import OurTeam from 'Pages/OurTeam';
import Products from 'Pages/Products';
import Updates from 'Pages/Updates';
import Contact from 'Pages/Contact';
import { primary1, primary8 } from 'Constants/Colors';

const { Content, Footer } = Layout;
const { Paragraph } = Typography;

const PageLayout = styled(Layout)`
  min-height: 100vh;
`;

const PageContent = styled(Content)`
  padding-top: 4.9rem;
  background: white;
  display: flex;
  flex-flow: column;

  > :last-child {
    flex: auto;
  }
`;

const PageFooter = styled(Footer)`
  background: ${primary8};
`;

const FooterText = styled(Paragraph)`
  && {
    margin: 0;
    color: ${primary1};
  }
`;

const CopyrightContainer = styled.div`
  && {
    font-size: 1.25rem;
    text-align: center;
    color: ${primary1};
  }
`;

function App() {
  return (
    <Router>
      <PageLayout>
        <NavBar />

        <PageContent>
          <Switch>
            <Route path="/about" component={About} />
            <Route path="/our-team" component={OurTeam} />
            <Route path="/products" component={Products} />
            <Route path="/updates" component={Updates} />
            <Route path="/contact" component={Contact} />
            <Route path="/" component={Home} />
          </Switch>
        </PageContent>

        <PageFooter>
          <div>
            <FooterText copyable>info@dawatek.com</FooterText>
            <FooterText>408-475-4955</FooterText>
            <FooterText>800 Wenonah Ave</FooterText>
            <FooterText>Oak Park, IL 60304</FooterText>
          </div>
          <CopyrightContainer>© 2021 Dawatek</CopyrightContainer>
        </PageFooter>
      </PageLayout>

    </Router>
  );
}

export default App;
