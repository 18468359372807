export const primary1 = "#fff2e8";
export const primary2 = "#ffd8bf";
export const primary3 = "#ffbb96";
export const primary4 = "#ff9c6e";
export const primary5 = "#ff7a45";
export const primary6 = "#fa541c";
export const primary7 = "#d4380d";
export const primary8 = "#ad2102";
export const primary9 = "#871400";
export const primary10 = "#610b00";

export const whiteText = "rgba(255, 255, 255, 0.95)";
