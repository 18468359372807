import {
	Typography,
	Tabs,
	List,
	Card,
} from 'antd';
import styled from 'styled-components';

import isaacImg from 'Assets/Images/isaac-chadri.jpg';
import davinaImg from 'Assets/Images/davina-moossazadeh.png';
import pjImg from 'Assets/Images/peter-chadri.jpg';
import leelaImg from 'Assets/Images/leela-krishna-raavi.jpg';
import sergioImg from 'Assets/Images/sergio-loubriel.jpg';
import sesiliImg from 'Assets/Images/sesili-koberidze.jpeg';
import jordanImg from 'Assets/Images/jordan-moossazadeh.png';
import eddieImg from 'Assets/Images/eddie-guen-murray.jpg';
import graceImg from 'Assets/Images/grace-e-berry.jpg';
import howardImg from 'Assets/Images/howard-k-oneil.jpg';
import markImg from 'Assets/Images/mark-junadian.jpg';
import johnImg from 'Assets/Images/john-hibbeln.jpg';
import hamImg from 'Assets/Images/ham-serunjogi.jpg';
import jacobImg from 'Assets/Images/jacob-d-willig-onwuachi.png';
import jonathanImg from 'Assets/Images/jonathan-gortat.jpg';
import susanaImg from 'Assets/Images/susana-valencia.jpg';
import tonyImg from 'Assets/Images/tony-vargas.jpg';
import merryImg from 'Assets/Images/merry-marwig.jpeg';
import omoImg from 'Assets/Images/omo-aisagbonhi.jpg';

import ColorSection from 'Components/PageSections/ColorSection';
import SectionContent from 'Components/PageSections/SectionContent';

import { MAX_CONTENT_WIDTH } from 'Constants/MaxContentWidth';
import { primary1, primary9 } from 'Constants/Colors';

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

type Member = {
	name: string;
	position?: string;
	link: string;
	subtitle?: string;
	bio?: string;
	image?: string;
};

const TeamHeader = styled(Title)`
	text-align: center;
`;

const TabsContainer = styled.div`
	width: 100%;

	.ant-tabs-nav, .ant-tabs-nav::before, .ant-list-item {
		border-bottom: 0;
	}

	.ant-card {
		background: ${primary1};
	}

	.ant-card-meta-description {
		color: ${primary9};
	}
`;

const PastContributorsHeader = styled(Paragraph)`
	font-size: 24px;
	margin-top: 30px;
`;

const MemberCard = styled(Card)<{ noSpace: boolean }>`
	&& {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-left: 25px;
		margin-top: ${p => p.noSpace ? 1 : 2}rem;

		.ant-card-body {
			padding: 0 0 0 30px;
		}
	}
`;

const MemberImageContainer = styled.div`
	display: inline-block;
	position: relative;
	overflow: hidden;
	width: 200px;
	height: 200px;

	img {
		position: absolute;
		margin: auto;
		width: 100%;
		height: auto;
		top: -100%;
		bottom: -100%;
	}
`;

const MemberNameContainer = styled.div`
	display: inline-block;
`;

const MemberName = styled(Paragraph)`
	&& {
		margin: 0;
	}
`;

const MemberBio = styled(Paragraph)`
	&& {
		margin-top: 15px;
		margin-bottom: 0;
	}
`;

const AdvisorsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 40px;
	justify-items: center;
	margin-top: 40px;
	width: ${MAX_CONTENT_WIDTH};
`;

const AdvisorCard = styled(Card)`
	width: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
`;

const RoundImageContainer = styled.div`
	overflow: hidden;
	border-radius: 50%;
	width: 200px;
	height: 200px;

	img {
		display: inline;
		margin: 0 auto;
		width: 100%;
		height: auto;
	}
`;



const founders: Member[] = [
	{
		name: "Isaac Chadri",
		position: "CEO",
		link: "https://www.linkedin.com/in/isaac-chadri-8895206a/",
		bio: "Isaac directs the company and works to conceptualize, research and develop the products " +
			"created. Isaac seeks to create advanced medical devices that are more cost-effective than " +
			"current devices on the market and that are easy for physicians to use with minimal risk " +
			"and high reward to the patient. His desire to create medical devices surfaced while " +
			"designing Physical Chemistry lab protocols for research as an undergraduate student at " +
			"Grinnell College, from which he graduated in 2015 with a Bachelors in General Chemistry. " +
			"He is currently a Graduate-Student-At-Large (GSAL) at University of Chicago where he is " +
			"taking courses to prepare himself to apply for an MD-PhD in Biotechnology, combining his " + 
			"passion to help others with his love for technology.",
		image: isaacImg,
	},
	{
		name: "Davina Moossazadeh",
		position: "CTO",
		link: "https://www.linkedin.com/in/davina-moos/",
		bio: "Davina graduated from the University of Chicago in 2018 with a bachelor’s in statistics. " +
			"In school, she worked on a number of projects at the intersection of science and medicine, " +
			"including a ten-week global health fellowship in Panama, where she studied the prevalence " +
			"of toxoplasmosis among pregnant women. Davina works as a data scientist at Next Insurance, " +
			"an insurtech company that provides online insurance to small businesses. At Dawatek, she " +
			"employs her keen understanding of analytical tools to process spectroscopy data and build " +
			"models to accurately identify the compounds in this data. Davina is passionate about using " +
			"data and analytics to make medicine accessible at a global level.",
		image: davinaImg,
	},
	{
		name: 'Peter Chadri, MBA',
		position: "COO",
		link: "https://www.linkedin.com/in/pchadri/",
		bio: "After studying International Relations at Stanford, Peter worked in a growth stage " +
			"fintech firm Silicon Valley in various product development roles, from quality assurance " +
			"to product management. He then completed  an MBA at Kellogg where he focused on Finance " +
			"and Organizational Behaviour. He works to create innovative teams that tackle global " +
			"problems by focusing on the human aspects of a problem.",
		image: pjImg,
	}
];

const contributors: Member[] = [
	{
		name: "Leela Krishna Raavi, MS",
		position: "Chief Engineering Officer",
		subtitle: "Data Scientist, XSELL Technologies",
		link: "https://www.linkedin.com/in/lraavi/",
		bio: "Leela's academic background is in computer science and software development, and his " +
			"professional experience is primarily in data science. He has 3 years of professional " +
			"experience working as a data scientist, providing Artificial Intelligence-enhanced customer " +
			"engagement and driving results across industries. His data science expertise is in deep " +
			"learning models, specifically neural networks and natural language processing algorithms. He " +
			"leads all engineering efforts at Dawatek, including software engineering and software-hardware integration.",
		image: leelaImg,
	},
	{
		name: "Dr. Sergio Loubriel, DMD",
		position: "Chief Medical Officer",
		subtitle: "Dentist, Lawrence Plaza Dental",
		link: "https://www.jeffersonparkdentist.com/dentists/",
		bio: "Sergio earned his Doctor of Dental Medicine from Midwestern University, where he was exposed " +
			"to innovative and technical procedures. Since his time at Midwestern University, he has been " +
			"passionate about helping and improving the health of his patients at DuPage Community Health Center " +
			"and Aunt Martha’s Community Health Center. During his free time, he continues to educate myself on " +
			"the most current dental technology, procedures, and practices. He leads all medical efforts at " +
			"Dawatek, particularly as they pertain to oral cancer and other oral diseases. ",
		image: sergioImg,
	},
	{
		name: "Sesili Koberidze",
		position: "Intern",
		subtitle: "BA Candidate, Biochemistry & Political Science, Grinnell College",
		link: "https://www.linkedin.com/in/sesili-koberidze-b47063146/",
		bio: "Sesili Koberidze is a fourth year at Grinnell College majoring in Biological Chemistry and Political Science. " + 
			"Her passions include classical art and she enjoyes reading literature in her free time.",
		image: sesiliImg,
	},
	{
		name: "Jordan Moossazadeh",
		position: "Web Developer",
		subtitle: "BA Candidate, Computer Science, UC Berkeley",
		link: "https://www.linkedin.com/in/jordan-moossazadeh/",
		bio: "",
		image: jordanImg,
	},
	{
		name: "Eddie Guen-Murray",
		position: "Co-Inventor",
		subtitle: "Global Neurophysiologist, Nielsen",
		link: "https://www.linkedin.com/in/edward-guen-murray-2b5a32ab/",
		image: eddieImg,
	}
];

const advisors: Member[] = [
	{
		name: "Dr. Omonigho \"Omo\" Aisagbonhi, MD, PhD",
		subtitle:"Assistant Clinical Professor of Pathology, UCSD",
		link: "https://profiles.ucsd.edu/omonigho.aisagbonhi",
		image: omoImg,
	},
	{
		name: "Dr. Grace E. Berry, MD",
		subtitle: "Clinical Associate of Medicine, University of Chicago Section of Hospital Medicine",
		link: "https://www.uchicagomedicine.org/find-a-physician/physician/grace-e-berry",
		image: graceImg,
	},
	{
		name: "Dr. Howard K. O'Neil, MD",
		subtitle: "Interventional Radiology & Diagnostic Radiology Specialist, AMITA Health",
		link: "https://doctors.amitahealth.org/howard-o-neil-hinsdale-interventional-radiology-diagnostic-radiology",
		image: howardImg,
	},
	{
		name: "Dr. Mark Jundanian, MD",
		subtitle: "Interventional Radiology & Diagnostic Radiology Specialist, AMITA Health",
		link: "https://doctors.amitahealth.org/mark-jundanian-chicago-interventional-radiology-diagnostic-radiology",
		image: markImg,
	},
	{
		name: "Dr. John Hibbeln, MD, FACR",
		subtitle: "Diagnostic Radiology Specialist & Professor, Loyola University",
		link: "https://loyolamedicine.org/doctor/john-hibbeln",
		image: johnImg,
	},
	{
		name: "Ham Serunjogi",
		subtitle: "Co-Founder & CEO, Chipper Cash",
		link: "https://www.linkedin.com/in/hamserunjogi/",
		image: hamImg,
	},
	{
		name: "Dr. Jacob D. Willig-Onwuachi, PhD",
		subtitle: "Clinical Professor, Boston University",
		link: "https://www.linkedin.com/in/jacob-willig-onwuachi-35b861a/",
		image: jacobImg,
	},
	{
		name: "Jonathan Gortat, MBA, CLP",
		subtitle: "Assistant Director, Office of Technology Management at University of Illinois at Chicago",
		link: "https://www.linkedin.com/in/jonathan-gortat-mba-clp-3b242a6/",
		image: jonathanImg,
	},
	{
		name: "Susana Valencia, MPH",
		subtitle: "Clinical Study Manager, Astellas Pharma",
		link: "https://www.linkedin.com/in/susana-valencia-a51714b/",
		image: susanaImg,
	},
	{
		name: "Tony Vargas, MBA, VCP",
		subtitle: "Systems Engineer, Berkeley Research Group",
		link: "https://www.linkedin.com/in/antoniovargas124/",
		image: tonyImg,
	},
	{
		name: "Merry Marwig",
		subtitle: "Privacy & Cybersecurity Market Research Analyst, G2",
		link: "https://www.linkedin.com/in/marwig/",
		image: merryImg,
	}
];

const team = [
	{
		name: "Founders",
		data: founders,
	},
	{
		name: "Contributors",
		data: contributors,
	}
];

function OurTeam() {
	return (
		<>
			<ColorSection>
				<SectionContent vertical paddingTop={50}>
					{/* <TeamHeader>Our Team</TeamHeader> */}
					<TabsContainer>
						<Tabs defaultActiveKey="0">
							{team.map(group => (
								<TabPane tab={group.name} key={group.name}>
									<List
										dataSource={group.data}
										renderItem={(member, memberIndex) => (
											<>
												{member.name === "Eddie Guen-Murray" && <PastContributorsHeader>Past Contributors</PastContributorsHeader>}
												<List.Item>
													<MemberCard
														bordered={false}
														noSpace={memberIndex === 0 || member.name === "Eddie Guen-Murray"}
														cover={member.image &&
															<MemberImageContainer>
																<a href={member.link} target="_blank" rel="noopener noreferrer">
																	<img
																		alt={member.name.split(",")[0]}
																		src={member.image}
																		width={200}
																	/>
																</a>
															</MemberImageContainer>
														}
													>
														<Card.Meta
															title={
																<MemberNameContainer>
																	<a href={member.link} target="_blank" rel="noopener noreferrer">
																		<MemberName>{member.name}</MemberName>
																	</a>
																	{member.position && <MemberName>{member.position}</MemberName>}
																</MemberNameContainer>
															}
															description={member.subtitle || ""}
														/>
														{member.bio && <MemberBio>{member.bio}</MemberBio>}
													</MemberCard>
												</List.Item>
											</>
										)}
									>
									</List>
								</TabPane>
							))}
							<TabPane tab="Advisors" key="Advisors" style={{width: "100%"}}>
								<AdvisorsContainer>
									{advisors.map(advisor => (
										<AdvisorCard
											bordered={false}
											cover={
												<RoundImageContainer>
													<a href={advisor.link} target="_blank" rel="noopener noreferrer">
														<img
															alt={advisor.name.split(",")[0]}
															src={advisor.image}
														/>
													</a>
												</RoundImageContainer>
											}
										>
											<Card.Meta
												title={
													<MemberNameContainer>
														<a href={advisor.link} target="_blank" rel="noopener noreferrer">
															<MemberName>{advisor.name}</MemberName>
														</a>
													</MemberNameContainer>
												}
												description={advisor.subtitle || ""}
											/>
										</AdvisorCard>
									))}
								</AdvisorsContainer>
							</TabPane>
						</Tabs>
					</TabsContainer>
				</SectionContent>
			</ColorSection>
		</>
	);
}

export default OurTeam;
