import { useState } from 'react';
import styled from 'styled-components';
import {
	Form,
	Input,
	Select,
	Checkbox,
	Typography,
	Button,
} from 'antd';
import countryData from 'react-select-country-list';

import ColorSection from 'Components/PageSections/ColorSection';
import SectionContent from 'Components/PageSections/SectionContent';

const { Paragraph } = Typography;

const ContactForm = styled(Form)`
	display: flex;
	justify-content: space-between;

	> :first-child {
		margin-right: 40px;
	}

	> * {
		flex: 0 1 50%;
	}
`;

const HorizontalInputGroup = styled(Input.Group)`
	display: flex;
	justify-content: space-between;

	> :first-child {
		margin-right: 10px;
	}

	> * {
		flex: 0 1 50%;
	}

	&& > .ant-form-item {
		max-width: 50%;
	}
	
	&& .ant-select {
		width: 100%;
	}
`;

const NewsletterInfo = styled(Paragraph)`
	margin-top: 0.8rem;
	margin-left: 24px;
`;

function Contact() {
	const countryList = countryData().getData();
	const [form] = Form.useForm();
	const [prefContactMethod, setPrefContactMethod] = useState("email");

	const onFinish = (values: any) => {
		console.log({ values });
	};

	const handlePrefContactMethod = (value: any) => {
		setPrefContactMethod(value);
	}

	return (
		<>
			<ColorSection>
				<SectionContent paddingTop={80} paddingBottom={80}>
					<ContactForm
						form={form}
						name="contactForm"
						layout="vertical"
						onFinish={onFinish}
						requiredMark
						scrollToFirstError
					>
						<div>
							<Paragraph>Please tell us a bit about yourself and how you would like to stay in touch.</Paragraph>
							<Form.Item label="Name" required>
								<HorizontalInputGroup>
									<Form.Item name="firstName" required noStyle>
										<Input type="name" placeholder="First" />
									</Form.Item>
									<Form.Item name="lastName" required noStyle>
										<Input type="name" placeholder="Last" />
									</Form.Item>
								</HorizontalInputGroup>
							</Form.Item>
							<Form.Item label="Email" required>
								<Input name="email" type="email" />
							</Form.Item>
							<Form.Item label="Company" name="company">
								<Input.TextArea autoSize />
							</Form.Item>
							<Form.Item label="Position Held" name="position">
								<Input.TextArea autoSize />
							</Form.Item>
							<Form.Item label="Location">
								<HorizontalInputGroup>
									<Form.Item name="city" noStyle>
										<Input.TextArea placeholder="City" autoSize />
									</Form.Item>
									<Form.Item name="country">
										<Select options={countryList} placeholder="Country" showSearch />
									</Form.Item>
								</HorizontalInputGroup>
							</Form.Item>
							<HorizontalInputGroup>
								<Form.Item label="Phone Number" name="phoneNumber" required={prefContactMethod === "phone"}>
									<Input type="tel" />
								</Form.Item>
								<Form.Item label="Preferred Method of Contact" name="prefContactMethod">
									<Select defaultValue="email" onSelect={handlePrefContactMethod}>
										<Select.Option value="email">Email</Select.Option>
										<Select.Option value="phone">Phone</Select.Option>
									</Select>
								</Form.Item>
							</HorizontalInputGroup>
							<Form.Item name="newsletter" valuePropName="checked">
								<Checkbox>I would like to receive the monthly Dawatek newsletter</Checkbox>
								<NewsletterInfo>We hate spam and don't have the time to send you any. We'll only send you a monthly update and any news that merits an announcement.</NewsletterInfo>
							</Form.Item>
						</div>

						<div>
							<Form.Item label="Let us know what's on your mind. Have a question? Ask away.">
								<Input.TextArea autoSize={{ minRows: 8 }} />
							</Form.Item>
							<Form.Item>
								<Button
									type="primary"
									htmlType="submit"
									size="large"
								>
									Submit
							</Button>
							</Form.Item>
						</div>
					</ContactForm>
				</SectionContent>
			</ColorSection>
		</>
	);
}

export default Contact;
