import {
	Typography
} from 'antd';
import styled from 'styled-components';

import ColorSection from 'Components/PageSections/ColorSection';
import SectionContent from 'Components/PageSections/SectionContent';

const { Title, Paragraph } = Typography;

const Header = styled(Title)`
	margin-bottom: 20px;
`;

const BodyText = styled(Paragraph)`
	font-size: 22px;
`;

function About() {
	return (
		<>
			<ColorSection>
				<SectionContent vertical centered paddingTop={100}>
					<Header>Our Mission</Header>
					<BodyText>
						Dawatek is a biotech company whose mission is to create affordable and advanced
						diagnostic medical devices.
					</BodyText>
				</SectionContent>
			</ColorSection>

			<section>
				<SectionContent vertical centered>
					<Header>Our Vision</Header>
					<BodyText>
						Our vision is that of a world in which nobody suffers due to being unable to afford access to medical technology.
					</BodyText>
				</SectionContent>
			</section>

			<ColorSection>
				<SectionContent vertical centered>
					<Header>History</Header>
					<BodyText>
						Our founders were inspired by the life and death of their aunt from colon cancer due,
						in part, to a lack of affordable diagnostic technologies in Kenya. Peter Chadri introduced 
						his younger brother Isaac Chadri to salsa dancing, and Isaac met Davina Moosazadeh and Leela Krishna
						at the dance studio where Isaac teaches dance. They have worked together before on a dance platform,
						and are now working together to achieve Dawatek's mission.
					</BodyText>
				</SectionContent>
			</ColorSection>
		</>
	);
}

export default About;
