import {
	Button,
	Carousel,
	Typography,
} from 'antd';
import styled from 'styled-components';

import bean from 'Assets/Images/bean.jpg';
import isaac from 'Assets/Images/isaac-test-tube.jpeg';
import labGang from 'Assets/Images/lab-gang.jpg';
import stairsGang from 'Assets/Images/stairs-gang.jpg';
import beanMasked from 'Assets/Images/bean-masked.jpg';
import whiteboard from 'Assets/Images/whiteboard.jpg';
import labLaugh from 'Assets/Images/lab-laugh.jpg';

import scientist from 'Assets/Images/black-scientist.jpg';
import doctor from 'Assets/Images/doctor.jpeg';
import patient from 'Assets/Images/patient.jpeg';
import insurance from 'Assets/Images/health-insurance.png';

import ColorSection from 'Components/PageSections/ColorSection';
import SectionContent from 'Components/PageSections/SectionContent';

import { MAX_CONTENT_WIDTH } from 'Constants/MaxContentWidth';

const { Title, Paragraph } = Typography;

const carouselImages: string[] = [
	bean,
	isaac,
	labGang,
	beanMasked,
	whiteboard,
	stairsGang,
	labLaugh,
];

const HeroSection = styled.section`
	margin-top: -4.9rem;
`;

const ImageCarousel = styled(Carousel)`
	width: 100%;
`;

const Slide = styled.div<{ src: string }>`
	width: 100%;
	height: 43rem;
	overflow: hidden;
	filter: brightness(30%);
	background-image: url(${p => p.src});
	background-size: cover;
`;

const HeroText = styled.div`
	position: absolute;
	z-index: 1;
	left: 0; right: 0;
	padding: 11rem 200px;
	width: 100%;
	max-width: ${MAX_CONTENT_WIDTH}px;
	margin: auto;
`;

const WhiteHeader = styled(Title)`
	&& {
		color: rgba(255, 255, 255, 0.95);
	}
`;

const BigBodyText = styled(Paragraph) <{ center?: boolean }>`
	font-size: 1.5rem;
	text-align: ${p => p.center ? "center" : "left"};
	margin: 0;
`;

const ThreePsBody = styled(BigBodyText)`
	width: 800px;
`;

const PageImageContainer = styled.div`
	overflow: hidden;
	width: 500px;
	height: 300px;

	img {
		display: inline;
		margin: 0 auto;
		width: 100%;
		height: auto;
	}
`;

function Home() {
	return (
		<>
			<HeroSection>
				<HeroText>
					<WhiteHeader level={1}>Your infectious disease result <br /> within 5 minutes</WhiteHeader>
					<Button
						type="primary"
						shape="round"
						size="large"
						href="https://www.gofundme.com/f/chicago-rapid-covid-test-challenge"
						target="_blank"
						rel="noopener noreferrer"
					>
						Learn about our race to develop our first rapid test
					</Button>
				</HeroText>
				<ImageCarousel autoplay>
					{carouselImages.map((url, i) =>
						<Slide key={i} src={url} />
					)}
				</ImageCarousel>
			</HeroSection>

			<ColorSection>
				<SectionContent>
					<div>
						<Title>Current Diagnostic Methods Are<br />Too Slow, Expensive, or Harmful</Title>
						<BigBodyText style={{width: "800px"}}>
							Dawatek is here to fix that. Our diagnostic platform screens for a variety of
							medical conditions at the same time, while prioritizing the <strong>3 P's</strong>:
						</BigBodyText>
					</div>
					<PageImageContainer>
						<img src={scientist} alt="Scientist" />
					</PageImageContainer>
				</SectionContent>
			</ColorSection>

			<section>
				<SectionContent>
					<PageImageContainer>
						<img src={patient} alt="Patients" />
					</PageImageContainer>
					<div>
						<Title>Patients</Title>
						<ThreePsBody>Returns accurate results within 5 minutes.</ThreePsBody>
					</div>
				</SectionContent>
			</section>

			<ColorSection>
				<SectionContent>
					<div>
						<Title>Providers</Title>
						<ThreePsBody>Saves doctors time and money by testing for multiple conditions simultaneously.</ThreePsBody>
					</div>
					<PageImageContainer>
						<img src={doctor} alt="Doctor" />
					</PageImageContainer>
				</SectionContent>
			</ColorSection>

			<section>
				<SectionContent>
					<PageImageContainer>
						<img src={insurance} alt="Payers" />
					</PageImageContainer>
					<div>
						<Title>Payers</Title>
						<ThreePsBody>Significantly cheaper than the current market standard.</ThreePsBody>
					</div>
				</SectionContent>
			</section>
		
			<ColorSection>
				<SectionContent vertical>
					<Title>An Opportunity to Advance</Title>
					<BigBodyText center>
						Our flagship product is the RTK, which simultaneously diagnoses various bacterial, viral, and chronic diseases—such as cancer and COVID-19—in under 5 minutes. It uses lasers and machine learning to detect the chemical signature of these diseases in body fluids.
					</BigBodyText>
					<BigBodyText center>
						The problem we're tackling is the amount of time it takes to get a disease diagnosis, which takes 2–8 days on average, and much longer in low-income areas. This can lead to disease transmission by asymptomatic individuals, over-prescription of medication, misdiagnosis, and malpractice, among other harms to both the patient and the provider.
					</BigBodyText>
					<BigBodyText center>
						Dawatek focuses on standardizing the diagnostic processes for many diseases into a uniform design that is simple, fast, and inexpensive. Dawatek’s RTK diagnoses infectious diseases earlier, resulting in fewer infections and allowing medical professionals to provide higher standards of care for other conditions. The RTK also improves the value proposition for primary healthcare providers, allowing them to offer diagnostic services in-office, instead of sending their patients to testing labs.
				</BigBodyText>
				</SectionContent>
			</ColorSection>
		</>
	);
}

export default Home;
