import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';

import { ReactComponent as DawatekLogo } from 'Assets/Images/dawatek-logo.svg';
import { MAX_CONTENT_WIDTH } from 'Constants/MaxContentWidth';
import { primary1, primary8, primary10 } from 'Constants/Colors';

const MenuBarContainer = styled.div<{ scrolled: boolean; currentPage: string }>`
	position: fixed;
	z-index: 100;
	width: 100%;
	box-shadow: ${p => p.scrolled ? "0 0 1.875rem rgba(0, 0, 0, 0.3)" : "none"};
	transition: all 0.2s ease;
	background-color: ${p => p.currentPage === "/" ?
	(p: any) => p.scrolled ? primary8 : "transparent" :
		(p: any) => p.scrolled ? "white" : primary1
	};
`;

const MenuBar = styled(Menu)`
	max-width: ${MAX_CONTENT_WIDTH}px;
	margin: auto;
	border: 0;
  padding: 0 180px;
	background: transparent;
`;

const MenuItem = styled(Menu.Item) <{ scrolled: boolean; currentPage?: string }>`
  && {
    float: right;
    height: ${p => p.scrolled ? 4.5 : 5}rem;
    line-height: ${p => p.scrolled ? 4.5 : 5}rem;
    font-size: 1.25rem;
    font-weight: 500;
		transition: all 0.2s ease;

    &&:hover {
      border-bottom: 2px solid ${p => p.currentPage === "/" ? primary1 : primary10};
    }

    a {
      color: ${p => p.currentPage === "/" ? primary1 : primary10};

      :hover {
        color: ${p => p.currentPage === "/" ? primary1 : primary10};
      }
    }
  }
`;

const MenuHome = styled(MenuItem)`
	&& {
		float: left;
		border-bottom: 0;
		transition: all 0.2s ease;
	}

	&&&&:hover {
		border-bottom: 0;
		opacity: 0.7;
	}
`;

const Logo = styled(DawatekLogo) <{ scrolled: boolean }>`
  height: 3rem;
	margin-top: ${p => p.scrolled ? 0.5 : 0.7}rem;
	transition: all 0.2s ease;
`;

function NavBar({ location }: any) {
	const [scrolled, setScrolled] = useState(false);
	const currentPage = location.pathname;

	const handleScroll = useCallback(() => {
		if (window.scrollY > (currentPage === "/" ? 100 : 60)) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	}, [currentPage]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, [handleScroll]);
	
	return (
		<MenuBarContainer
			scrolled={scrolled}
			currentPage={currentPage}
		>
			<MenuBar
				mode="horizontal"
				selectedKeys={[]}
			>
				<MenuHome key="home" scrolled={scrolled}>
					<Link to="/">
						<Logo scrolled={scrolled} fill={currentPage === "/" ? primary1 : primary10} strokeWidth={0} />
					</Link>
				</MenuHome>
				<MenuItem key="contact" scrolled={scrolled} currentPage={currentPage}>
					<Link to="/contact">Contact</Link>
				</MenuItem>
				<MenuItem key="updates" scrolled={scrolled} currentPage={currentPage}>
					<Link to="/updates">Updates</Link>
				</MenuItem>
				<MenuItem key="products" scrolled={scrolled} currentPage={currentPage}>
					<Link to="/products">Products</Link>
				</MenuItem>
				<MenuItem key="ourTeam" scrolled={scrolled} currentPage={currentPage}>
					<Link to="/our-team">Our Team</Link>
				</MenuItem>
				<MenuItem key="about" scrolled={scrolled} currentPage={currentPage}>
					<Link to="/about">About</Link>
				</MenuItem>
			</MenuBar>
		</MenuBarContainer>
	);
}

export default withRouter(NavBar);
